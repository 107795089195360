import { Link } from "react-router-dom";
import './Sidebar.css';
import { isAdmin } from "./AuthContext";

export function Sidebar(props: {
  children: JSX.Element,
}) {
  const { value: isAdminValue } = isAdmin();
  return (
    <>
      <div className="sidebar">
        <Link to="/profile">Profile</Link>
        <Link to="/tasks">Tasks</Link>
        <Link to="/tasks/submit">Submit task</Link>
        {isAdminValue && <Link to="/admin">Admin</Link>}
      </div>
      <div className="main">
        {props.children}
      </div>
    </>
  )
}
