import { useTitle } from "react-use";

export function ErrorDisplay(props: {
  error: Error,
}) {
  return <p>{props.error.name}: {props.error.message}</p>
}
  
export function Loading() {
  return null;
}

export function NotFoundPage() {
  useTitle("Not found | lectory-web");
  return <h3>Not Found</h3>
}
