import { useAuth } from "../AuthContext";
import { useAsync, useTitle } from "react-use";
import { ErrorDisplay, Loading } from "../utils";

export function Profile() {
  useTitle("Profile | lectory-web");
  const auth = useAuth();
  const { value: identity, error, loading } = useAsync(() => auth.identity(), [auth]);
  if (error) {
    return <ErrorDisplay error={error} />
  }
  if (loading || !identity) {
    return <Loading />
  }
  const { profile } = identity;
  return (
    <>
      <h3>Profile</h3>
      <p>Username: {profile.preferred_username}</p>
      <p>Display name: {profile.display_name}</p>
      <p>Application roles:</p>
      <ul>
        {profile.roles.map((role: string) => (
          <li key={role}>{role}</li>
        ))}
      </ul>
      <p>Groups:</p>
      <ul>
        {profile.groups.map((group: string) => (
          <li key={group}>{group}</li>
        ))}
      </ul>
    </>
  );
}
