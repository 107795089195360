import { useTitle } from "react-use";
import { UploadTask, getTasks, useFetch } from "../api";
import { ErrorDisplay, Loading } from "../utils";
import useSWR from "swr";
import { Link, useSearchParams } from "react-router-dom";
import './Tasks.css';
import { useEffect, useMemo } from "react";

function TaskDisplay(props: {
  task: UploadTask
}) {
  const { task } = props;
  return (
    <div className="taskListItem">
      <Link to={`/tasks/${task.id}`}>Inspect</Link>
      <p>ID: {task.id}</p>
      <p>State: {task.state}</p>
      <p>Created: {task.created_by} at {task.created_at.toLocaleString()}</p>
      <p>Source: share {task.source_share}, path {task.source_path}</p>
      <p>Channel: {task.target_platform}/{task.target_channel_ref}</p>
      <p>Playlist: {task.target_playlist_ref}</p>
      <p>Title: {task.target_title}</p>
      <p>Description:</p>
      <p><i>{task.target_description}</i></p>
    </div>
  );
}

export function Tasks() {
  useTitle("Tasks | lectory-web");
  const { fetch } = useFetch();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    setSearchParams({ limit: '10', page: '1' });
  }, []);
  const searchLimit = searchParams.get('limit');
  const limit = useMemo(
    () => parseInt(searchLimit ?? '10', 10),
    [searchLimit],
  );
  const searchPage = searchParams.get('page');
  const page = useMemo(
    () => parseInt(searchPage ?? '1', 10),
    [searchPage],
  );
  const { data: tasks, error, isLoading } = useSWR({ limit, start: (page - 1) * limit, fetch }, getTasks, { refreshInterval: 5_000 });
  if (error) {
    return <ErrorDisplay error={error} />
  }
  if (isLoading || !tasks) {
    return <Loading />
  }
  return (
    <>
      <h3>Last {limit} tasks (showing {tasks.length})</h3>
      <p>
        Page size:
        <select
          value={limit}
          onChange={event => setSearchParams(current => ({ ...current, limit: event.target.value }))}
        >
          <option value={'10'}>10</option>
          <option value={'25'}>25</option>
          <option value={'100'}>100</option>
        </select>
        <button
          onClick={() => setSearchParams(current => ({ ...current, page: (page - 1).toString() }))}
          disabled={page <= 1}
        >
          Previous page
        </button>
        <button
          onClick={() => setSearchParams(current => ({ ...current, page: (page + 1).toString() }))}
        >
          Next page
        </button>
      </p>
      <p>
        
      </p>
      {tasks.map(task => (
        <TaskDisplay key={task.id} task={task} />
      ))}
    </>
  );
}
