import { useMemo, useState } from "react";
import { Channel, Playlist } from "../api"
import './AdminChannelDetail.css';

export type AdminChannelDetailProps = {
  channel: Channel
}

type PlaylistDetailProps = {
  playlist: Playlist
}

const PlaylistDetail = (props: PlaylistDetailProps) => {
  const { playlist } = props;
  return (
    <div className="adminPlaylistDetail">
      <div>Title: {playlist.title}</div>
      <div>ID: {playlist.playlist_id}</div>
    </div>
  )
}

export const AdminChannelDetail = (props: AdminChannelDetailProps) => {
  const { channel } = props;
  const [filter, setFilter] = useState('');
  const playlists = useMemo(() => {
    return channel.playlists.filter(
      pls => pls.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
    );
  }, [channel.playlists, filter]);
  return (
    <div className="adminChannelDetail">
      <div>Description: {channel.description}</div>
      <div>ID: {channel.id}</div>
      <div>Playlist filter: <input value={filter} onChange={event => setFilter(event.target.value)}></input></div>
      <div>
        Playlists:
        {playlists.map(pls => (
          <details key={pls.playlist_id}>
            <summary>{pls.title}</summary>
            <PlaylistDetail playlist={pls} />
          </details>
        ))}
      </div>
    </div>
  )
}
