import {
  Route,
  Routes,
  BrowserRouter,
  Navigate,
} from "react-router-dom";
import { WithAuthProvider } from './AuthContext';
import { Profile } from './pages/Profile';
import { Tasks } from "./pages/Tasks";
import { Sidebar } from "./Sidebar";
import { NotFoundPage } from "./utils";
import { TaskDetails } from "./pages/TaskDetails";
import { SubmitTask } from "./pages/SubmitTask";
import { Admin } from "./pages/Admin";

function App() {
  return (
    <WithAuthProvider>
      <BrowserRouter>
        <Sidebar>
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/profile" />}
            />
            <Route
              path="/profile"
              element={<Profile />}
            />
            <Route
              path="/tasks/submit"
              element={<SubmitTask />}
              />
            <Route
              path="/tasks/:task_id"
              element={<TaskDetails />}
              />
            <Route
              path="/tasks"
              element={<Tasks />}
            />
            <Route
              path="/admin"
              element={<Admin />}
            />
            <Route
              path="/*"
              element={<NotFoundPage />}
            />
          </Routes>
        </Sidebar>
      </BrowserRouter>
    </WithAuthProvider>
  );
}

export default App;
