import { useCallback, useMemo } from "react";
import { AuthContext, useAuth } from "./AuthContext";

export const PLATFORM_YOUTUBE = 'youtube' as const;
export const PLATFORM_VK = 'vk' as const;

export type Platform =
  | typeof PLATFORM_YOUTUBE
  | typeof PLATFORM_VK

export type Playlist = {
  platform: Platform
  playlist_id: string
  title: string
}

export type Channel = {
  id: string
  description: string
  playlists: Playlist[]
}

export const TASK_STATE_PENDING = 'pending' as const;
export const TASK_STATE_RUNNING = 'running' as const;
export const TASK_STATE_COMPLETED = 'completed' as const;
export const TASK_STATE_FAILED = 'failed' as const;

export type TaskState =
  | typeof TASK_STATE_PENDING
  | typeof TASK_STATE_RUNNING
  | typeof TASK_STATE_COMPLETED
  | typeof TASK_STATE_FAILED


export interface UploadTask {
  id: string
  state: TaskState
  created_by: string
  created_at: Date
  source_share: string
  source_path: string
  target_platform: Platform
  target_title: string
  target_description: string
  target_channel_ref: string
  target_playlist_ref: string
}

export interface UploadTaskEvent {
  id: string
  task_id: string
  timestamp: Date
  body: string
}

export interface CreateUploadTaskReq {
  share: string
  path: string
  thumbnail_path: string
  platform: Platform
  title: string
  description: string
  channel_ref: string
  playlist_ref: string
}

export interface VkCheckLoginResult {
  user_id: number
  device_id: string
  scope: string
  expires_at: number
}

export interface YoutubeCheckLoginResult {
  scopes: string[]
  expiry: Date
}

export interface StartLoginResult {
  redirect_uri: string
}

export type Fetch = (url: string | URL, init?: RequestInit) => Promise<any>

class AuthMiddleware {
  private readonly auth: AuthContext;

  constructor(auth: AuthContext) {
    this.auth = auth;
  }

  async fetch(url: string | URL, init?: RequestInit) {
    const { token } = await this.auth.identity();
    const rsp = await fetch(url, {
      ...init,
      headers: {
        'authorization': `Bearer ${token}`,
        ...init?.headers,
      },
    });
    if (!rsp.ok) {
      if (!rsp.ok) {
        throw new Error(`${rsp.status} ${rsp.statusText}: ${await rsp.text()}`);
      }
    }
    return await rsp.json();
  }
}

export function useFetch() {
  const auth = useAuth();
  const middleware = useMemo(() => new AuthMiddleware(auth), [auth]);
  return {
    fetch: useCallback<typeof middleware.fetch>(
      (...args) => middleware.fetch(...args),
      [middleware]),
  };
}

export function getChannels(options: {
  fetch: Fetch,
}): Promise<{
  vk: Channel[],
  youtube: Channel[],
}> {
  return options.fetch('/api/playlists');
}

export function getShares(options: {
  fetch: Fetch,
}): Promise<{
  shares: string[]
}> {
  return options.fetch('/api/shares');
}

export async function getTasks(options: {
  limit: number,
  start: number,
  fetch: Fetch,
}): Promise<UploadTask[]> {
  const data = await options.fetch(`/api/tasks?limit=${options.limit}&start=${options.start}`);
  return data.map((task: any) => ({
    ...task,
    created_at: new Date(task.created_at),
  }));
}

export async function getTask(options: {
  task_id: string,
  fetch: Fetch,
}): Promise<UploadTask> {
  const data = await options.fetch(`/api/tasks/${options.task_id}`);
  return {
    ...data,
    created_at: new Date(data.created_at),
  };
}

export async function getTaskEvents(options: {
  task_id: string,
  limit: number,
  fetch: Fetch,
}): Promise<UploadTaskEvent[]> {
  const data = await options.fetch(`/api/tasks/${options.task_id}/events?limit=${options.limit}`);
  return data.map((event: any) => ({
    ...event,
    timestamp: new Date(event.timestamp),
  }));
}

export async function createTask(options: {
  task: CreateUploadTaskReq,
  fetch: Fetch,
}): Promise<UploadTask> {
  const data = await options.fetch(`/api/tasks`, {
    method: 'POST',
    body: JSON.stringify(options.task),
    headers: {
      'content-type': 'application/json',
    },
  })
  return data;
}

export async function checkLoginVk(options: {
  fetch: Fetch,
}): Promise<VkCheckLoginResult> {
  const data = await options.fetch(`/api/vk/status`, {
    method: 'POST',
  })
  return data;
}

export async function startLoginVk(options: {
  fetch: Fetch,
}): Promise<StartLoginResult> {
  const data = await options.fetch(`/api/vk/login`, {
    method: 'POST',
  });
  return data;
}

export async function syncPlaylistsVk(options: {
  fetch: Fetch,
}): Promise<{}> {
  await options.fetch('/api/playlists/vk/sync', {
    method: 'POST',
  });
  return {};
}

export async function checkLoginYoutube(options: {
  fetch: Fetch,
}): Promise<YoutubeCheckLoginResult> {
  const data = await options.fetch(`/api/youtube/status`, {
    method: 'POST',
  })
  return {
    ...data,
    expiry: new Date(data.expiry * 1000),
  };
}

export async function startLoginYoutube(options: {
  fetch: Fetch,
}): Promise<StartLoginResult> {
  const data = await options.fetch(`/api/youtube/login`, {
    method: 'POST',
  });
  return data;
}

export async function syncPlaylistsYoutube(options: {
  fetch: Fetch,
}): Promise<{}> {
  await options.fetch('/api/playlists/youtube/sync', {
    method: 'POST',
  });
  return {};
}
