import { useTitle } from "react-use";
import { getTask, getTaskEvents, useFetch } from "../api";
import { ErrorDisplay, Loading } from "../utils";
import useSWR from 'swr';
import { useParams } from "react-router-dom";

function TaskEvents(props: {
  task_id: string,
}) {
  const { task_id } = props;
  const limit = 20;
  const { fetch } = useFetch();
  const { data: events, error, isLoading } = useSWR(
    { task_id, limit, fetch },
    getTaskEvents,
    { refreshInterval: 5_000 },
  );
  if (error) {
    return <ErrorDisplay error={error} />
  }
  if (isLoading || !events) {
    return <p>Loading events...</p>
  }
  return (
    <div>
      <h3>Events</h3>
      <ul>
        {events.map(event => (
          <li key={event.id}>
            <p>{event.timestamp.toLocaleString()} {event.body}</p>
          </li>
        ))}
      </ul>
    </div>
  )
}

export function TaskDetails() {
  const { task_id } = useParams();
  if (!task_id) {
    throw new Error('Missing parameter: task_id');
  }
  useTitle(`Task ${task_id?.slice(0, 8)}-... | lectory-web`);
  const { fetch } = useFetch();
  const { data: task, error, isLoading } = useSWR({ task_id, fetch }, getTask, { refreshInterval: 5_000 });
  if (error) {
    return <ErrorDisplay error={error} />
  }
  if (isLoading || !task) {
    return <Loading />
  }
  return (
    <>
      <h3>Task {task_id}</h3>
      <p>State: {task.state}</p>
      <p>Created: {task.created_by} at {task.created_at.toLocaleString()} UTC</p>
      <p>Source: share {task.source_share}, path {task.source_path}</p>
      <p>Channel: {task.target_channel_ref}</p>
      <p>Playlist: {task.target_playlist_ref}</p>
      <p>Title: {task.target_title}</p>
      <p>Description:</p>
      <p><i>{task.target_description}</i></p>
      <TaskEvents task_id={task_id} />
    </>
  );
}
