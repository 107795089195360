import { useAsync, useTitle } from "react-use";
import { PLATFORM_VK, PLATFORM_YOUTUBE, createTask, getChannels, getShares, useFetch } from "../api";
import { ErrorDisplay } from "../utils";
import { useNavigate } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import './SubmitTask.css';

export function SubmitTask() {
  useTitle('Submit task | lectory-web');
  const navigate = useNavigate();
  const { fetch } = useFetch();
  const { value: sharesResult } = useAsync(() => getShares({ fetch }), [fetch]);
  const { value: channels } = useAsync(() => getChannels({ fetch }), []);
  const [channel, setChannel] = useState('');
  const [playlist, setPlaylist] = useState('');
  const [share, setShare] = useState('');
  const [path, setPath] = useState('');
  const [thumbnailPath, setThumbnailPath] = useState('');
  const [platform, setPlatform] = useState<string>('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const submit = useCallback(async () => {
    try {
      if (!share || !path || !thumbnailPath || !platform || !title || !description) { 
        console.error('Incomplete form');
        return;
      }
      if (platform !== PLATFORM_YOUTUBE && platform !== PLATFORM_VK) {
        console.error('Invalid platform');
        return;
      }
      const { id } = await createTask({
        task: {
          share,
          path,
          thumbnail_path: thumbnailPath,
          platform,
          title,
          description,
          channel_ref: channel,
          playlist_ref: playlist,
        },
        fetch,
      });
      setLoading(false);
      navigate(`/tasks/${id}`);
    } catch (error: any) {
      console.error('Failed to create task', error);
      setError(error);
      setLoading(false);
    }
  }, [fetch, share, path, platform, channel, playlist, title, description]);
  const [filter, setFilter] = useState('');
  const matchingPlaylists = useMemo(() => {
    if (!channels) {
      return [];
    }
    if (platform !== PLATFORM_VK && platform !== PLATFORM_YOUTUBE) {
      return [];
    }
    const channelPlaylists = channels[platform].find(
      ch => ch.id === channel
    )?.playlists ?? [];
    return channelPlaylists.filter(
      pls => pls.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
    );
  }, [channels, platform, channel, filter]);
  const disabled = loading
    || !share || !path || !platform || !title || !description
    || !platform || !channel || !playlist;
  return (
    <>
      <h3>Submit task</h3>
      <p>
        <span className="inputWithHelp">
          <span>Platform:</span>
          <select value={platform} onChange={event => {
            setPlatform(event.target.value);
            if (event.target.value !== platform) {
              setChannel('');
              setPlaylist('');
            }
          }}>
            <option value=''></option>
            <option value={PLATFORM_YOUTUBE}>YouTube</option>
            <option value={PLATFORM_VK}>VK</option>
          </select>
        </span>
      </p>
      {(platform == PLATFORM_VK || platform === PLATFORM_YOUTUBE) && (
        <>
          {channels !== undefined && (
            <>
              <p>
                <span className="inputWithHelp">
                  <span>Channel:</span>
                  <select value={channel} onChange={event => {
                    setChannel(event.target.value);
                    if (event.target.value !== channel) {
                      setPlaylist('');
                    }
                  }}>
                    <option value=''></option>
                    {channels[platform].map(ch => (
                      <option key={ch.id} value={ch.id}>{ch.description}</option>
                    ))}
                  </select>
                </span>
              </p>
              {!!channel && (
                <>
                  <p>
                    <span className="inputWithHelp">
                      <span>Playlist filter:</span>
                      <input value={filter} onChange={event => {
                        setFilter(event.target.value);
                        if (filter !== event.target.value) {
                          setPlaylist('');
                        }
                      }} />
                    </span>
                  </p>
                  <p>
                    <span className="inputWithHelp">
                      <span>Playlist:</span>
                      <select value={playlist} onChange={event => setPlaylist(event.target.value)}>
                        <option value=''></option>
                        {matchingPlaylists.map(pls => (
                          <option key={pls.playlist_id} value={pls.playlist_id}>{pls.title}</option>
                        ))}
                      </select>
                    </span>
                  </p>
                </>
              )}
            </>
          ) || (
            <p>Loading channels...</p>
          )}
        </>
      )}
      <p>
        <span className="inputWithHelp">
          <span>Share:</span>
          <select value={share} onChange={event => setShare(event.target.value)}>
            <option value=''></option>
            {sharesResult?.shares.map(share => (
              <option key={share} value={share}>{share}</option>
            ))}
          </select>
        </span>
      </p>
      <p>
        <span className="inputWithHelp">
          <span>Video path:</span>
          <input size={80} value={path} onChange={event => setPath(event.target.value)} />
          <small>2023 осень/3курс_функан_коновалов/Лекция 3/lect3.mkv</small>
        </span>
      </p>
      <p>
        <span className="inputWithHelp">
          <span>Thumbnail path:</span>
          <input size={80} value={thumbnailPath} onChange={event => setThumbnailPath(event.target.value)} />
          <small>2023 осень/3курс_функан_коновалов/Лекция 3/lect3.png</small>
        </span>
      </p>
      <p>
        <span className="inputWithHelp">
          <span>Video title:</span>
          <input size={100} value={title} onChange={event => setTitle(event.target.value)} />
          <small className="inputHelp">Функциональный анализ 3. Связь метрического и топологического пространств</small>
        </span>
      </p>
      <p>
        <span className="inputWithHelp">
          <span>Video description:</span>
          <textarea cols={80} rows={20} value={description} onChange={event => setDescription(event.target.value)}/>
        </span>
      </p>
      {error && <ErrorDisplay error={error} />}
      {loading && <p><i>Processing...</i></p>}
      <p>
        <button
          onClick={submit}
          disabled={
            disabled
          }
        >
          Submit
        </button>
      </p>
    </>
  );
}
