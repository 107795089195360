import { useAsync } from 'react-use';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { ErrorDisplay, Loading } from './utils';

export interface Profile {
  sub: string
  display_name: string
  preferred_username: string
  groups: string[]
  roles: string[]
  exp: number
}

export interface Identity {
  token: string
  profile: Profile
}

export interface AuthContext {
  identity(): Promise<Identity>
}

const ADMIN_ROLE = 'admin';

export const authContext = createContext<AuthContext | null>(null);

class AuthContextImpl implements AuthContext {
  private currentIdentity?: Identity
  private refreshPromise: Promise<Identity>

  constructor() {
    this.refreshPromise = this.refresh();
  }

  async identity(): Promise<Identity> {
    const expiresInMs = (this.currentIdentity?.profile.exp ?? 0) - Date.now();
    if (this.currentIdentity && expiresInMs >= 60_000) {
      return this.currentIdentity;
    } else {
      return this.refreshPromise;
    }
  }

  private async refresh(): Promise<Identity> {
    const rsp = await fetch('/api/login/token', { method: 'POST' });
    if (!rsp.ok) {
      const location = rsp.headers.get('location');
      if (rsp.status == 401 && location) {
        window.location.replace(location);
      } else {
        throw new Error(`Authentication failed: ${rsp.status} ${rsp.statusText}: ${await rsp.text()}`);
      }
    }
    const { id_token, profile } = await rsp.json();
    const expiresInMs = profile.exp * 1_000 - Date.now();
    const timeoutMs = 0.8 * expiresInMs;
    setTimeout(() => { this.refreshPromise = this.refresh() }, timeoutMs);
    return {
      token: id_token,
      profile: {
        sub: profile.sub,
        display_name: profile.name,
        preferred_username: profile.preferred_username,
        groups: profile.groups,
        roles: profile.roles,
        exp: profile.exp,
      },
    };
  }
}

export const WithAuthProvider = (props: {
  children: JSX.Element,
}) => {
  const authContextImpl = useMemo(() => new AuthContextImpl(), []);
  const { error, loading } = useAsync(useCallback(() => authContextImpl.identity(), [authContextImpl]));
  if (error) {
    return <ErrorDisplay error={error} />
  }
  if (loading) {
    return <Loading />
  }
  return <authContext.Provider value={authContextImpl}>{props.children}</authContext.Provider>
}

export const useAuth = () => {
  const auth = useContext(authContext);
  if (!auth) {
    throw new Error('Missing authContext');
  }
  return auth;
}

export const isAdmin = () => {
  const auth = useAuth();
  return useAsync(async () => {
    const identity = await auth.identity();
    return identity.profile.roles.includes(ADMIN_ROLE);
  }, [auth]);
}
