import { useAsync, useTitle } from "react-use";
import { Loading } from "../utils";
import { VkCheckLoginResult, YoutubeCheckLoginResult, checkLoginVk, checkLoginYoutube, getChannels, getShares, startLoginVk, startLoginYoutube, syncPlaylistsVk, syncPlaylistsYoutube, useFetch } from "../api";
import { Fragment, useCallback, useState } from "react";
import { isAdmin } from "../AuthContext";
import './Admin.css';
import { AdminChannelDetail } from "./AdminChannelDetail";

export function Admin() {
  useTitle("Admin | lectory-web");
  const { fetch } = useFetch();

  const [checkLoginResultVk, setCheckLoginResultVk] = useState<VkCheckLoginResult | null>(null);
  const doCheckLoginVk = useCallback(async () => {
    setCheckLoginResultVk(await checkLoginVk({ fetch }));
  }, [fetch]);
  const doLoginVk = useCallback(async () => {
    const { redirect_uri } = await startLoginVk({ fetch });
    window.location.href = redirect_uri;
  }, [fetch]);

  const [syncPlsVkResult, setSyncPlsVkResult] = useState({});
  const doSyncPlaylistsVk = useCallback(async () => {
    setSyncPlsVkResult(
      await syncPlaylistsVk({ fetch })
    );
  }, [fetch]);

  const [checkLoginResultYoutube, setCheckLoginResultYoutube] = useState<YoutubeCheckLoginResult | null>(null);
  const doCheckLoginYoutube = useCallback(async () => {
    setCheckLoginResultYoutube(await checkLoginYoutube({ fetch }));
  }, [fetch]);
  const doLoginYoutube = useCallback(async () => {
    const { redirect_uri } = await startLoginYoutube({ fetch });
    window.location.href = redirect_uri;
  }, [fetch]);

  const [syncPlsYoutubeResult, setSyncPlsYoutubeResult] = useState({});
  const doSyncPlaylistsYoutube = useCallback(async () => {
    setSyncPlsYoutubeResult(
      await syncPlaylistsYoutube({ fetch })
      );
    }, [fetch]);

    const { value: sharesResult } = useAsync(() => getShares({ fetch }), [fetch]);
    const { value: channels } = useAsync(
    () => getChannels({ fetch }),
    [syncPlsVkResult, syncPlsYoutubeResult],
  );

  const { value: isAdminValue } = isAdmin();
  if (isAdminValue === undefined) {
    return <Loading />
  } else if (isAdminValue === false) {
    return (
      <>
        <h3>Admin</h3>
        <p>Access denied (not an admin)</p>
      </>
    );
  }

  return (
    <>
      <h3>Admin</h3>
      <div className='platformAdminDetails'>
        <h4>Sources</h4>
        {sharesResult && (
          <>
            <p>Shares:</p>
            {sharesResult.shares.map(s => <p key={s}>- {s}</p>)}
          </>
        ) || (
          <Loading/>
        )}
      </div>
      <div className='platformAdminDetails'>
        <h4>
          VK
          {' '}
          <button onClick={doSyncPlaylistsVk}>Sync playlists (update from VK)</button>
          {' '}
          <button onClick={doCheckLoginVk}>Check login status</button>
          {' '}
          <button onClick={doLoginVk}>Reset login (use my account)</button>
        </h4>
        {checkLoginResultVk && (
          <>
            <p>User ID: {checkLoginResultVk.user_id}</p>
            <p>Device ID: {checkLoginResultVk.device_id}</p>
            <p>Scope: {checkLoginResultVk.scope}</p>
            <p>Expires: {new Date(checkLoginResultVk.expires_at * 1000).toLocaleString()}</p>
          </>
        )}
        {channels && (
          <>
            Channels:
            {channels.vk.map(ch => (
              <details key={ch.id}>
                <summary>{ch.description}</summary>
                <AdminChannelDetail channel={ch} />
              </details>
            ))}
          </>
        )}
      </div>
      <div className='platformAdminDetails'>
        <h4>
          YouTube
          {' '}
          <button onClick={doSyncPlaylistsYoutube}>Sync playlists (update from YouTube)</button>
          {' '}
          <button onClick={doCheckLoginYoutube}>Check login status</button>
          {' '}
          <button onClick={doLoginYoutube}>Reset login (use my account)</button>
        </h4>
        {checkLoginResultYoutube && (
          <>
            <p>Scopes: {checkLoginResultYoutube.scopes.join(', ')}</p>
            <p>Expiry: {checkLoginResultYoutube.expiry.toLocaleString()}</p>
          </>
        )}
        {channels && (
          <>
            Channels:
            {channels.youtube.map(ch => (
              <AdminChannelDetail key={ch.id} channel={ch} />
            ))}
          </>
        )}
      </div>
    </>
  );
}
